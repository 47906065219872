<template>
  <div class="container">
    <custom-header></custom-header>
    <div class="login-container">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="new-password"
        label-position="left"
      >
        <div class="title-container">
          <h3 class="title checked">绑定已有账号</h3>
        </div>
        <el-form-item prop="mobilePhone">
          <div class="form-item">
            <div class="svg-container svg-container_login">
              <img src="@/assets/images/user.png" alt="" />
            </div>
            <el-input
              v-model="loginForm.mobilePhone"
              name="mobilePhone"
              type="text"
              maxlength="11"
              auto-complete="new-password"
              placeholder="请输入手机号"
            />
          </div>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <div class="form-item">
            <span class="svg-container">
              <img src="@/assets/images/Password.png" alt="" />
            </span>
            <el-input
              v-model="loginForm.verifyCode"
              name="verifyCode"
              auto-complete="new-password"
              placeholder="请输入验证码"
              @keyup.enter.native="handleLogin"
            />
            <!-- <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span> -->
            <el-button
              @click="sendCode"
              :disabled="smsFlag"
              :style="{
                fontSize: '16px',
                backgroundColor: sendColor,
                borderColor: sendColor,
                color: '#26201e',
                marginRight: '10px',
              }"
            >
              {{ sendTime }}
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="loginPassword">
          <div class="form-item">
            <span class="svg-container">
              <img src="@/assets/images/Password.png" alt="" />
            </span>
            <el-input
              :type="passwordType"
              v-model="loginForm.loginPassword"
              name="loginPassword"
              auto-complete="new-password"
              placeholder="请输入密码"
              @keyup.enter.native="handleLogin"
            />
            <!-- <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span> -->
          </div>
        </el-form-item>
        <el-form-item prop="passwdCheck">
          <div class="form-item">
            <span class="svg-container">
              <img src="@/assets/images/Password.png" alt="" />
            </span>
            <el-input
              :type="passwordType"
              v-model="loginForm.passwdCheck"
              name="passwdCheck"
              auto-complete="new-password"
              placeholder="请输入确认密码"
              @keyup.enter.native="handleLogin"
            />
            <!-- <span class="show-pwd" @click="showPwd">
            <svg-icon icon-class="eye" />
          </span> -->
          </div>
        </el-form-item>
        <div class="my-btn">
          <el-button
            :loading="loading"
            type="primary"
            @click.native.prevent="handleLogin"
            >登录
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getQrcodeAndState, sendSms, wechatBindPhone } from "@/api/index";
// import { wechatBind, getBindingInformation } from '@/api/index'

export default {
  name: "bindPage",

  data() {
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else if (!/^1[0-9]{10}$/.test(value)) {
        callback("请填写正确的手机号");
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      let reg = /^[^\s]{6,10}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("密码6-10位，不包含空格"));
      } else {
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.loginForm.loginPassword) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      sendTime: "获取验证码",
      sendColor: "#FDB64F",
      snsMsgWait: 60,
      smsFlag: false,
      passwordType: "password",
      loginForm: {
        accessToken: "",
        loginPassword: "",
        mobilePhone: "",
        openid: "",
        verifyCode: "",
      },
      loginRules: {
        mobilePhone: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        verifyCode: [
          { required: true, message: "验证码不允许为空", trigger: "blur" },
        ],
        loginPassword: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        passwdCheck: [
          {
            required: true,
            validator: validatePassCheck,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getQrcodeAndState();
      // let vm = this
      // if (this.$route.query.token) {
      //   this.$store.commit("SET_TOKEN", this.$route.query.token);
      //   this.getUserInfo();
      // } else {

      // }
    },
    getQrcodeAndState() {
      getQrcodeAndState({
        code: this.$route.query.code,
        state: this.$route.query.state,
      })
        .then((res) => {
          console.log("res", res);
          this.$store.commit("SET_TOKEN", res.data.token);
          this.getUserInfo();
        })
        .catch((err) => {
          console.log("err", err);
          if (err.message == "未查询到网站应用openId相关数据，需要绑定手机号") {
            this.loginForm.accessToken = err.data.access_token;
            this.loginForm.openid = err.data.openid;
          } else {
            this.$message.error(err.message);
          }
        });
    },
    getUserInfo() {
      this.$store.dispatch("GetUserInfo", this.loginForm).then(() => {
        this.$router.replace({ name: "index" });
      });
    },
    // 账号登录
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          wechatBindPhone(this.loginForm).then((res) => {
            if (res.status == 200) {
              this.$store.commit("SET_TOKEN", res.data.token);
              this.$store.dispatch("GetUserInfo", this.loginForm).then(() => {
                
                this.$router.replace({ name: "index" });
                window.open("https://www.atl-life.com/userCenter?token=" + localStorage.getItem('Atoken'));
                window.close();
                // 哈哈哈哈或
              });
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 获取验证码
    sendCode() {
      if (!/^1\d{10}$/.test(this.loginForm.mobilePhone)) {
        this.$message.info("请填写正确的手机号");
        return;
      }
      var params = {
        mobile: this.loginForm.mobilePhone,
        type: 4,
      };
      sendSms(params).then(() => {
        this.$message.success("短信发送成功");
        this.countDown();
      });
    },
    countDown() {
      // 60秒后重新获取验证码
      var inter = setInterval(
        function () {
          this.smsFlag = true;
          this.sendColor = "#E0E0E0";
          this.sendTime = this.snsMsgWait + "s后可重发";
          this.snsMsgWait = this.snsMsgWait - 1;
          if (this.snsMsgWait < 0) {
            clearInterval(inter);
            this.smsFlag = false;
            this.sendColor = "#FDB64F";
            this.sendTime = "获取验证码";
            this.snsMsgWait = 60;
          }
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$bg: #fdb64f;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  position: absolute;
  height: 100%;
  width: 100%;
  min-width: 1000px;
  background: url("~@/assets/images/denglu.png") no-repeat;
  background-size: 100% 100%;
  .login-form {
    position: absolute;
    top: 20%;
    right: 13%;
    width: 30%;
  }

  .form-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_login {
      font-size: 20px;
    }
  }

  .title-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 22px;
      font-weight: 400;
      color: #888585;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
      position: relative;
      cursor: pointer;
      &.checked {
        color: $bg;
      }
      &.checked::after {
        width: 40px;
        height: 3px;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -12px;
        transform: translateX(-50%);
        z-index: 1;
        background: $bg;
      }
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
.forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 15px;
  a {
    font-size: 16px;
    color: #fff;
  }
}
.my-btn {
  margin: 30px auto;
  text-align: center;

  button {
    width: 100%;
    height: 52px;
    background: $bg;
    border-radius: 33px;
    color: #26201e;
    font-size: 22px;
  }
  img {
    margin-top: 30px;
    width: 45px;
    height: 45px;
  }
}
.homepage-hero-module {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
</style>
